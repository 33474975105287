//ua確認

export default class UA {
  constructor() {
  }

	static isSP(){
		var ua = navigator.userAgent;
    return (ua.indexOf('iPhone') > -1 || ua.indexOf('iPod') > -1 || (ua.indexOf('Android') > -1 && ua.indexOf('Mobile') > -1) || ua.indexOf('Windows Phone') > -1 || ua.indexOf('BlackBerry') > -1);
	}
	static isTAB(){
		var ua = navigator.userAgent;
    return (ua.indexOf('iPad') > -1 || (ua.indexOf('Android') > -1 && ua.indexOf('Mobile') == -1));
  }
	static isIOS(){
		var ua = navigator.userAgent;
    return (ua.indexOf('iPhone') > -1 || ua.indexOf('iPad') > -1 || ua.indexOf('iPod') > -1)
  }
	static isAndroid(){
		var ua = navigator.userAgent;
    return (ua.indexOf('Android') > -1);
  }
	static isIE(){
		var ua = navigator.userAgent;
    return (ua.indexOf('Trident') > -1);
  }
	static isEdge(){
		var ua = navigator.userAgent;
    return (ua.indexOf('Edge') > -1);
  }

  static setBodyClass(){
    if(this.isIE())$("body").addClass("ie");
    if(this.isSP()){
      $("html").addClass("sp");
    }else if(this.isTAB()){
      $("html").addClass("sp tab");
    }else{
      $("html").addClass("pc");
    }
  }

}
