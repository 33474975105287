global.THREE = require('three');
global.TWEEN = require("tween");
global.BG = {};

import UA from '../_util/_ua.js';

var isDev = false;
document.write("<script type='text/javascript' src='"+WpRoot+"js/three_libs.js'></script>");
if(isDev){
	document.write("<script type='text/javascript' src='"+WpRoot+"js/lib/three/stats.min.js'></script>");
	document.write("<script type='text/javascript' src='"+WpRoot+"js/lib/three/dat.gui.min.js'></script>");
	var GuiPanel = require('../_util/_guiPanel.js');
}
var gl = require('../variables/_store');

var container, stats;
var camera, scene, renderer, light;
var controls, sphere, sky, cubeCamera;

const textureSize = [512, 512];
let waterNormalsTexture;
const waters = [];
const waterCnt = [2, 2];
const waterSize = [5000, 5000];
const repeatLimit = [5000, 5000];
const stageParam={};
const mouseParam={x:0, y:0, perX:0, perY:0};
const cameraParam = {
	angle: 0,
	degree: 0,
	speed: 0.5,
	baseAngle: -0.55
};
const skyParam = {
	distance: 400,
	inclination: 0.49,
	azimuth: 0.2,
	sunSpeed: 0.001,
	sunTime: 0.3,//0-1
	sunUpdateWait: 300

};;


jQuery(function($) {
	textureLoad();
});

function initGUI() {
	var panel = new dat.GUI();

	let folder = panel.addFolder('sun');
	folder.add( skyParam, 'inclination', -1, 1, 0.0001 ).listen();//.onChange( sunUpdate );
	folder.add( skyParam, 'azimuth', 0, 1, 0.0001 ).listen();
	folder.add( skyParam, 'sunSpeed', 0, 1, 0.0001 ).listen();
	folder.add( skyParam, 'sunTime', 0, 1, 0.0001 ).listen();
	folder.open();

	folder = panel.addFolder('camera');
	folder.add( camera.position, 'y', 0, 1000, 10 );
	folder.add( cameraParam, 'baseAngle', -1, 1, 0.01 );
	folder.open();

	folder = panel.addFolder('move');
	folder.add( cameraParam, 'speed', 0, 1, 0.01 );
	folder.open();
/*
	var uniforms = water.material.uniforms;
	var folder2 = panel.addFolder('Water');
	folder2.add( uniforms.distortionScale, 'value', 0, 8, 0.1 ).name( 'distortionScale' );
	folder2.add( uniforms.size, 'value', 0.1, 10, 0.1 ).name( 'size' );
	folder2.add( uniforms.alpha, 'value', 0.9, 1, .001 ).name( 'alpha' );
	folder2.open();
*/
	panel.close();
}

function textureLoad(){
	waterNormalsTexture = new THREE.TextureLoader().load( WpRoot + 'img/bg/waternormals.jpg', function ( texture ) {
		texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
		init();
		animate();
	} );
}

function init() {
	container = document.getElementById( 'bg' );
	//
	renderer = new THREE.WebGLRenderer();
	renderer.setPixelRatio( window.devicePixelRatio );
	renderer.setSize( window.innerWidth, window.innerHeight );
	container.appendChild( renderer.domElement );
	//
	scene = new THREE.Scene();
	//
	camera = new THREE.PerspectiveCamera( 55, window.innerWidth / window.innerHeight, 1, 20000 );
	camera.position.set( 30, 70, 100 );
	//
	light = new THREE.DirectionalLight( 0xffffff, 0.9 );
	scene.add( light );
	// Water
	const waterGeometry = new THREE.PlaneBufferGeometry( waterSize[0], waterSize[1] );
	for(let i=0; i<waterCnt[0]; i++){
		for(let j=0; j<waterCnt[1]; j++){
			const tx = waterNormalsTexture.clone();
			tx.needsUpdate = true;
			const wat = new THREE.Water(
				waterGeometry,
				{
					textureWidth: textureSize[0],
					textureHeight: textureSize[1],
					waterNormals: tx,
					alpha: 1,
					sunDirection: light.position.clone().normalize(),
					sunColor: 0x00516b,//0xffffff,
					waterColor: 0x001343,//0x001e0f
					distortionScale: 3.7,
					fog: scene.fog !== undefined
				}
			);
			wat.rotation.x = - Math.PI / 2;//面を上に向ける
			wat.position.x = -waterSize[0]*(i - (waterCnt[0]-1)/2);
			wat.position.z = -waterSize[1]*(waterCnt[1] - j%waterCnt[0] + 0.5);
			scene.add( wat );
			waters.push(wat);
		}
	}

	// Skybox
	sky = new THREE.Sky();
	var uniforms = sky.material.uniforms;
	uniforms[ 'turbidity' ].value = 10;
	uniforms[ 'rayleigh' ].value = 2;
	uniforms[ 'luminance' ].value = 1;
	uniforms[ 'mieCoefficient' ].value = 0.005;
	uniforms[ 'mieDirectionalG' ].value = 0.8;
	cubeCamera = new THREE.CubeCamera( 0.1, 1, 512 );
	cubeCamera.renderTarget.texture.generateMipmaps = true;
	cubeCamera.renderTarget.texture.minFilter = THREE.LinearMipMapLinearFilter;
	scene.background = cubeCamera.renderTarget;
	sunUpdate();
	//
	/*
	var geometry = new THREE.IcosahedronBufferGeometry( 20, 1 );
	var count = geometry.attributes.position.count;
	var colors = [];
	var color = new THREE.Color();
	for ( var i = 0; i < count; i += 3 ) {
		color.setHex( Math.random() * 0xffffff );
		colors.push( color.r, color.g, color.b );
		colors.push( color.r, color.g, color.b );
		colors.push( color.r, color.g, color.b );
	}
	geometry.addAttribute( 'color', new THREE.Float32BufferAttribute( colors, 3 ) );
	var material = new THREE.MeshStandardMaterial( {
		vertexColors: THREE.VertexColors,
		roughness: 0.0,
		flatShading: true,
		envMap: cubeCamera.renderTarget.texture,
		side: THREE.DoubleSide
	} );
	sphere = new THREE.Mesh( geometry, material );
	scene.add( sphere );
	*/
	//
	/*
	controls = new THREE.OrbitControls( camera, renderer.domElement );
	controls.maxPolarAngle = Math.PI * 0.495;
	controls.target.set( 0, 10, 0 );
	controls.minDistance = 40.0;
	controls.maxDistance = 200.0;
	controls.update();
	*/
	//
	if(isDev){
		stats = new Stats();
		container.appendChild( stats.dom );
	}
	//initGUI();
	onWindowResize();

	/*
	if(UA.isSP()){
		window.addEventListener( 'deviceorientation', onDeviceOrient, false );
	}else{
		window.addEventListener( 'mousemove', onMouseMove, false );
	}
	*/
	window.addEventListener( 'resize', onWindowResize, false );

	setInterval(function(){
		sunUpdate();
	},skyParam.sunUpdateWait);
}

function onWindowResize() {
	stageParam.width = container.scrollWidth;//window.innerWidth;
	stageParam.height = container.scrollHeight;//window.innerHeight;
	camera.aspect = stageParam.width / stageParam.height;
	camera.updateProjectionMatrix();
	renderer.setSize( stageParam.width, stageParam.height );

	if(UA.isEdge()){
		animate();
	}
}

function onMouseMove(evt){
	mouseParam.x = evt.pageX;
	//mouseParam.y = evt.pageY;
	//mouseParam.x = evt.screenX;
	mouseParam.y = evt.screenY;
	mouseParam.perX = mouseParam.x/stageParam.width*2 - 1;
	mouseParam.perY = mouseParam.y/stageParam.height*2 - 1;
}

function onDeviceOrient(evt){
	var alpha = evt.alpha;
  var beta = evt.beta;
  var gamma = evt.gamma;
	console.log(alpha,beta,gamma);
}



function animate() {
	if(!UA.isEdge()){
		requestAnimationFrame( animate );
	}

	//sunUpdate();
	cameraUpdate();
	waterUpdate();

	render();
	if(stats){
		stats.update();
	}

}
function render() {
	renderer.render( scene, camera );
}

function cameraUpdate(){
	cameraParam.degree += (mouseParam.perX * -50 - cameraParam.degree)*0.01;
	cameraParam.angle += (mouseParam.perY * -0.1 - cameraParam.angle)*0.01;

	const xadd = - Math.sin(cameraParam.degree*Math.PI/180) * cameraParam.speed;
	camera.position.x += xadd;
	const zadd = - Math.cos(cameraParam.degree*Math.PI/180) * cameraParam.speed;
	camera.position.z += zadd;
	camera.rotation.x = cameraParam.angle + cameraParam.baseAngle;
	camera.rotation.z = cameraParam.degree * 0.0005;
}

function waterUpdate(){
	var time = performance.now() * 0.001;

	for(let i=0; i<waters.length; i++){
		const _this = waters[i];
		_this.material.uniforms[ 'time' ].value += 1.0 / 60.0;
		/*
		if(_this.position.x - camera.position.x > repeatLimit[0]){
			_this.position.x -= waterSize[0]*waterCnt[0];
		}else if(_this.position.x - camera.position.x < -repeatLimit[0]){
			_this.position.x += waterSize[0]*waterCnt[0];
		}
		*/
		if(_this.position.z - camera.position.z > repeatLimit[1]){
			_this.position.z -= waterSize[1]*waterCnt[1];
		}else if(_this.position.z - camera.position.z < -repeatLimit[1]){
			_this.position.z += waterSize[1]*waterCnt[1];
		}
	}
}


function sunUpdate() {
	skyParam.sunTime = (skyParam.sunTime + skyParam.sunSpeed)%1;
	skyParam.inclination = -1 + skyParam.sunTime * 2;
	//skyParam.azimuth = 0.25+Math.cos(360*skyParam.sunTime*Math.PI/180)*0.25;
	//skyParam.azimuth -= mouseParam.perX * 0.00001;

	var theta = Math.PI * ( skyParam.inclination - 0.5 );
	var phi = 2 * Math.PI * ( skyParam.azimuth - 0.5 );
	//light.position.x = skyParam.distance * Math.cos( phi );
	light.position.x = -skyParam.distance * Math.sin( phi ) * Math.cos( theta ) * 0.8;
	light.position.y = skyParam.distance * Math.sin( phi ) * Math.sin( theta );
	light.position.z = -skyParam.distance;
	//light.position.z = skyParam.distance * Math.sin( phi ) * Math.cos( theta );
	sky.material.uniforms[ 'sunPosition' ].value = light.position.copy( light.position );
	for(let i=0; i<waters.length; i++){
		waters[i].material.uniforms[ 'sunDirection' ].value.copy( light.position ).normalize();
	}
	cubeCamera.update( renderer, sky );
}
