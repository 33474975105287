//<script src="js/libs/dat.gui.min.js"></script>
//https://threejs.org/examples/#webgl_animation_skinning_blending
var GuiPanel = function(){

};
GuiPanel.prototype.createPanel=function() {
	var panel = new dat.GUI( { width: 310 } );
	/*
	this.status = {
		'show model':            true,
		'modify idle weight':    0.0,
		'modify walk weight':    1.0,
		'modify run weight':     0.0
	};
	folder5.add( this.status, 'show model' );//.onChange( showModel );
	folder5.add( this.status, 'modify idle weight', 0.0, 1.0, 0.01 );//.listen().onChange( function ( weight ) { setWeight( idleAction, weight ); } );
	folder5.add( this.status, 'modify walk weight', 0.0, 1.0, 0.01 );//.listen().onChange( function ( weight ) { setWeight( walkAction, weight ); } );
	folder5.add( this.status, 'modify run weight', 0.0, 1.0, 0.01 );//.listen().onChange( function ( weight ) { setWeight( runAction, weight ); } );
	folder5.open();
*/
	//console.log(this.trg.camera);
	return panel;
};

module.exports = GuiPanel;
