var store = {
	bgColor: 0xfe4101,
	particleColor: 0x000000,
	group: null,
	container: null,
	stats: null,
	particlesData: [],
	camera: null,
	scene: null,
	renderer: null,
	positions: null,
	colors: null,
	particles: null,
	pointCloud: null,
	particlePositions: null,
	linesMesh: null,
	maxParticleCount: 1000,
	particleCount: 512,
	numConnected: null,
	vertexpos: null,
	colorpos: null,
	cameraTween: null,
	changeDuration: 1200,
	changeDelay: 1000,

	cameraOrbit: false,
	checkCamera: false,

	cameraPos: {
		x: 0,
		y: 0,
		z: 0,
		rx: 0,
		ry: 0,
		rz: 0
	},
	cameraTo: {
		x: 0,
		y: 0,
		z: 0,
		rx: 0,
		ry: 0,
		rz: 0
	},
	cameraBase: {
		x: -740,
		y: 150,
		z: 0,
		rx: 0,
		ry: -1.5,
		rz: -2.0
	},

	params: {
		showDots: true,
		showLines: true,
		minDistance: 150,
		limitConnections: true,
		maxConnections: 5,
		particleCount: 500,
		motionType: "",
	},

}

module.exports = store;
